export default {
  article_17_title: "Dodatki – aplikacja mobilna",
  article_18_title: "Dodatki – aplikacja mobilna – personalizacja",
  article_19_title: "Dodatki – płatności stripe",
  wb_hide_booking_employees: "Ukryj pracowników podczas rezerwacji",
  wb_og_image_url: "Open Graph URL grafiki (og:image)",
  wb_favicon_url: "Favicon URL",
  wb_elevation: "Cień",
  wb_show_sign_in_button: "Pokaż przycisk „Zaloguj się”",
  wb_show_sign_up_button: "Pokaż przycisk „Załóż konto”",
  wb_show_active_button: "Pokaż aktywny przycisk",
  wb_show_company_name: "Pokaż nazwę firmy",
  wb_button_size_type_x_small_title: "XS",
  wb_button_size_type_small_title: "S",
  wb_button_size_type_medium_title: "M",
  wb_button_size_type_large_title: "L",
  wb_button_size_type_x_large_title: "XL",
  wb_button_size_title: "Rozmiar przycisku",
  auth: "Autoryzacja",
  booking_created_no_notifications_label:
    "Nie informuj o stworzeniu wizyty (brak powiadomień)",
  booking_updated_no_notifications_label:
    "Nie informuj o zmianie/usunięciu wizyty",
  booking_deleted_no_notifications_label: "Nie informuj o usunięciu",
  booking_no_notifications_tooltip:
    "Klient oraz pracownik nie otrzymają powiadomień e-mail, SMS oraz Push po stworzeniu/zmianie wizyty. Otrzymają natomiast powiadomienia typu przypomnienie (jeśli masz je włączone).",
  recurring_booking: "Powtarzaj cyklicznie",
  repeat_every: "Powtarzaj co",
  day: "Dzień",
  week: "Tydzień",
  month: "Miesiąc",
  year: "Rok",
  day_plural_1: "Dzień",
  day_plural_2: "Dni",
  day_plural_3: "Dni",
  day_plural_4: "Dni",
  day_plural_5: "Dni",
  week_plural_1: "Tydzień",
  week_plural_2: "Tygodnie",
  week_plural_3: "Tygodnie",
  week_plural_4: "Tygodnie",
  week_plural_5: "Tygodni",
  month_plural_1: "Miesiąc",
  month_plural_2: "Miesiące",
  month_plural_3: "Miesiące",
  month_plural_4: "Miesiące",
  month_plural_5: "Miesiący",
  year_plural_1: "Rok",
  year_plural_2: "Lata",
  year_plural_3: "Lata",
  year_plural_4: "Lata",
  year_plural_5: "Lat",
  booking_plural_1: "Rezerwacja",
  booking_plural_2: "Rezerwacje",
  booking_plural_3: "Rezerwacje",
  booking_plural_4: "Rezerwacje",
  booking_plural_5: "Rezerwacji",
  ends_after_occurrences_1: "Zakończ po",
  ends_after_occurrences_2: "powtórzeniach",
  ends_at_date: "Zakończ dnia",
  delete_recurring_booking_title: "Usuwanie cyklicznego wydarzenia",
  delete_recurring_booking_question:
    "To wydarzenie jest wydarzeniem cyklicznym. Co chcesz usunąć?",
  delete_recurring_booking_option_1: "Tylko to jedno wydarzenie",
  delete_recurring_booking_option_2: "To wydarzenie oraz wszystkie kolejne",
  delete_recurring_booking_option_3:
    "Wszystkie wydarzenia, włącznie z tymi, które już się odbyły",
  all: "Wszystkie",
  payment_2: "Płatność",
  recurring_booking_ends_info:
    "Maksymalna liczba wydarzeń cyklicznych została przekroczona. Zmień parametry i spróbuj ponownie.",

  payment_method_stripe: "Stripe",
  payment_method_cash: "Gotówka",
  payment_method_transfer: "Przelew",
  payment_method_card: "Karta",
  payment_method_subscription: "Subskrypcja",
  payment_method_other: "Inne",

  booking_payment_transaction_create_title: "Zapłać za rezerwację",
  booking_payment_transaction_remove_title: "Usuń płatność za wizytę",
  payment_transaction_transaction_id: "Numer transakcji / komentarz",
  invalid_time: "Nieprawidłowa godzina",
  add_new_day: "Dodaj nowy dzień",
  max_people_title:
    "Maksymalna liczba klientów w tym samym czasie u jednego pracownika",
  wb_date_label: "Etykieta daty",
  wb_start_calendar_time: "Pokaż od godziny",
  wb_end_calendar_time: "Pokaż do godziny",
  wb_interval_minutes: "Minimalna długość wydarzenia w kalendarzu",
  minutes: "min",
  wb_booking_note: "Dodatkowe informacje",
  wb_require_phone_number: "Wymagaj telefonu w formularzu",
  wb_hide_signup: "Ukryj możliwość rejestracji",
  booking_form_custom_fields: "Pola formularza rezerwacji",
  booking_form_custom_fields_equal_note: "Oczekiwana wartość",
  clear_filters: "Wyczyść filtry",
  wb_hide_login: "Ukryj możliwość logowania",
  wb_hide_header: "Ukryj nagłówek",
  wb_hide_footer: "Ukryj stopkę",
  wb_add_element: "Dodaj pole formularza",
  wb_element_title: "Pole formularza",
  wb_dynamic_form_element_type_1: "Pole tekstowe",
  wb_dynamic_form_element_type_2: "Większe pole tekstowe",
  wb_dynamic_form_element_type_3: "Pole wyboru",
  wb_dynamic_form_element_type_4: "Przycisk opcji",
  wb_dynamic_form_element_type_5: "Pole e-mail",
  wb_dynamic_form_element_type_6: "Pole telefonu",
  wb_dynamic_form_element_type_7: "Adres",
  wb_dynamic_form_should_require: "Pole obowiązkowe",
  wb_dynamic_form_value_equals: "Wartość do sprawdzenia",
  wb_dummy_text_summary_headline: "Dziękujemy za dokonanie rezerwacji",
  wb_summary_headline: "Tytuł w podsumowaniu",
  wb_summary_text: "Tekst w podsumowaniu",
  wb_view_outlined: "Zarysowany widok",
  wb_view_flat: "Płaski widok",
  wb_view_rounded: "Zaokrąglenie widoku",
  wb_select_date_hour: "Tekst nad kalendarzem",
  wb_book_now_label: "Przycisk rezerwacji",
  wb_html_code: "Kod HTML",
  wb_zoom: "Powiększenie",
  wb_longitude: "Długość geograficzna",
  wb_latitude: "Szerokość geograficzna",
  wb_map_maker_hide: "Ukryj pinezkę na mapie",
  service_location: "Lokalizacja",
  service_locations: "Lokalizacje",
  service_location_info:
    "Lokalizacja definiuje sposób spotkania lub łączenia się z klientem. Ta informacja będzie widoczna na stronie potwierdzenia rezerwacji, a także zostanie dołączona do wysyłanych e-maili. Jeśli korzystasz z synchronizacji z zewnętrznym kalendarzem, informacja o lokalizacji zostanie dodana do danego wydarzenia. Masz możliwość dodania wielu lokalizacji, w takim przypadku klient zostanie poproszony o dokonanie wyboru.",
  service_location_1: "Adres",
  service_location_2: "Telefon",
  service_location_3: "Google Meet",
  service_location_4: "Inna",
  service_location_5: "Zoom",
  service_location_6: "Microsoft Teams",
  service_location_7: "Skype",
  service_location_8: "WhatsApp",
  select_service_location_label: "Wybierz lokalizację",
  service_location_1_data_label: "Wpisz adres lokalizacji",
  service_location_2_data_label: "Wpisz numer telefonu",
  service_location_3_data_label: "Wpisz dodatkowe informacje",
  wb_image_margin_bottom: "Odstęp w pikselach pod zdjęciem",
  wb_text_margin_bottom: "Odstęp w pikselach pod tekstem",
  plugins_fakturownia: "Fakturownia",
  fakturownia_description:
    "Fakturownia to program, który pozwala na automatyzację wystawiania faktur. Calendesk może przekazywać wszystkie informacje o płatnościach bezpośrednio do Twojego konta w Fakturownia.",
  fakturownia_description_2:
    "Dodaj poniżej Twój API token oraz adres konta. Obie te wartości znajdziesz po zalogowaniu się do swojego konta w Fakturownia. API Token znajdziesz w Ustawienia -> Ustawienia konta -> Integracja -> Kod autoryzacyjny API. Adres URL konta znajdziesz w Ustawienia -> Ustawienia konta.",
  fakturownia_enabled_title: "Włącz moduł Fakturownia",
  fakturownia_send_invoice_title:
    "Automatycznie wyślij fakturę na adres e-mail klienta po otrzymaniu płatności.",
  fakturownia_api_token_title: "API Token",
  fakturownia_gtu_codes_title: "Kody GTU",
  fakturownia_account_url_title: "Adres URL konta",
  stripe_id_label: "Identyfikator w Stripe",
  fakturownia_id_label: "Identyfikator w Fakturownia",
  private_notes_for_customer_label: "Prywatne notatki o kliencie",
  tax_label: "Podatek",
  wants_invoice_title: "Faktura",
  fakturownia_validation_error:
    "Calendesk nie może połączyć się z systemem Fakturownia. Podane dane są nieprawidłowe.",
  feature_not_available_title:
    "Niestety ta funkcja nie jest dostępna w Twoim planie. Zmień plan, aby odblokować wszystkie możliwości Calendesk.",
  only_subscriptions_label:
    "Możliwość rezerwacji tej usługi tylko dla klientów z subskrypcją.",
  subscriptions: "Subskrypcje",
  subscription: "Subskrypcja",
  subscriptions_list: "Lista subskrypcji",
  subscription_payments: "Płatność",
  each: "Każdy",
  subscription_name: "Nazwa subskrypcji w Calendesk",
  subscription_name_example: "np. Konsultacje miesięczne",
  invoice_info_1:
    "Zaznacz opcję wystawiania faktur, jeśli chcesz, aby system automatycznie wystawiał fakturę po poprawnej transakcji płatniczej (Wymaga poprawnej konfiguracji zewnętrznej wtyczki do wystawiania faktur VAT np. Fakturownia, którą znajdziesz w dodatkach).",
  wants_invoice_select_title: "Wystaw fakturę VAT po otrzymaniu płatności",
  tax_amount_title:
    "Wysokość podatku VAT w procentach, która zawiera się już w cenie",
  product_in_stripe: "Produkt w Stripe",
  product_price_in_stripe: "Cena produktu w Stripe",
  product_name: "Nazwa produktu w Stripe",
  add_new_stripe_product_title: "Dodaj nowy produkt w Stripe",
  add_new_stripe_product_price_title: "Dodaj nową cenę produktu w Stripe",
  subscription_recurring_title: "Płatność cykliczna",
  service_limits_label:
    "Ogranicz liczbę możliwych rezerwacji w ramach tej subskrypcji.",
  service_limits_count_label: "Maksymalna liczba rezerwacji",
  service_limits_reset_time: "Resetuj liczbę rezerwacji cyklicznie",
  available_services_in_subscription: "Dostępne usługi w subskrypcji",
  subscription_settings: "Ustawienia subskrypcji",
  payment_settings: "Ustawienia płatności",
  subscriptions_settings_information:
    "Zdecyduj, jaki rodzaj subskrypcji chcesz zaoferować swoim klientom. Możesz wybrać usługi, dla których subskrypcja będzie obowiązywać. Nazwij subskrypcję w sposób, który pozwoli klientowi zrozumieć jej cel. Możesz również zdecydować, czy subskrypcja ma mieć ograniczenia dotyczące rezerwacji wybranych usług (np. 10 rezerwacji). Ograniczenia mogą być resetowane co pewien czas (np. możesz ustawić 10 rezerwacji miesięcznie dla klienta).",
  subscription_payment_settings_information:
    "Zdecyduj, w jaki sposób Twoi klienci będą płacić za subskrypcję. Do tego potrzebujesz produktu w Stripe oraz ustalenia ceny, która zostanie utworzona na Twoim koncie Stripe (kliknij ikonę plusa, aby utworzyć). Płatność może być jednorazowa lub rozłożona w czasie (np. 100 zł co miesiąc). Dzięki różnym kombinacjom ustawień subskrypcji i płatności możesz tworzyć bardzo złożone subskrypcje (np. 10 rezerwacji na usługę miesięcznie z jednoroczną płatnością w wysokości 999 zł).",
  bookings_number: "Liczba rezerwacji",
  recurrent_bookings_reset: "Resetowane cyklicznie co",
  recurrent_payment_each: "Płatność cyklicznie co",
  recurrent_each: "Cyklicznie co",
  subscription_success_url: "Przekierowanie URL po poprawnej płatności",
  subscription_redirect_title: "Opcjonalne przekierowania",
  subscription_success_url_example: "Np. https://twoja-strona.pl/sukces",
  subscription_cancel_url: "Przekierowanie URL po niepoprawnej płatności",
  subscription_cancel_url_example: "Np. https://twoja-strona.pl/blad",
  subscription_redirect_question:
    "Gdzie przekierować użytkownika po poprawnej lub błędnej płatności za subskrypcję?",
  booking_payment_urls_question:
    "Gdzie przekierować użytkownika po poprawnej lub błędnej płatności za rezerwację (to ustawienie dotyczy tylko płatności przez stronę www w systemie Calendesk)?",
  select_subscription: "Wybierz subskrypcję",
  subscription_active: "Subskrypcja aktywna",
  remove_subscription_not_possible_error_notification:
    "Nie można usunąć aktywnych subskrypcji.",
  user_subscriptions: "Subskrypcje",
  active: "Aktywne",
  inactive: "Nieaktywne",
  initiated: "Nieopłacona",
  canceled: "Anulowane",
  no_user_subscriptions_info:
    "Nie masz jeszcze żadnych subskrypcji przypisanych do Twoich klientów. Kliknij poniżej, aby przypisać ręcznie subskrypcję klientowi lub poproś klienta o zakup subskrypcji bezpośrednio z Twojej strony.",
  go_to_subscriptions: "Przejdź do subskrypcji",
  stripe_subscription_id: "Stripe ID",
  provider_tag: "Metoda",
  card: "Karta",
  cash: "Gotówka",
  transfer: "Przelew",
  cancel_subscription_success: "Poprawnie anulowano wybrane subskrypcje.",
  cancel_subscription_fail: "Nie udało się anulować wybranych subskrypcji.",
  cancel_succeeded: "Anulowano pomyślnie",
  cancel_failed: "Podczas anulowania wystąpił błąd",
  ends_at: "Ważność",
  ends_at_default: "Ważność do końca okresu",
  number_of_user_subscriptions: "Liczba sprzedanych subskrypcji",
  number_of_simple_store_products: "Liczba sprzedanych produktów",
  number_of_active_user_subscriptions: "Aktywne subskrypcje",
  number_of_simple_store_product_transactions: "Wszystkie sprzedane produkty",
  number_of_simple_store_product_transactions_today:
    "Sprzedane produkty dzisiaj",
  number_of_simple_store_product_transactions_this_week:
    "Sprzedane produkty ten tydzień",
  number_of_bookings_by_amount_title: "Przychód z rezerwacji",
  number_of_user_subscriptions_by_amount_title: "Przychód z subskrypcji",
  number_of_simple_store_product_by_amount_title: "Przychód z produktów",
  disable_configuration: "Ukryj postęp w konfiguracji",
  enable_configuration: "Pokaż postęp w konfiguracji",
  quick_configuration: "Szybka konfiguracja",

  stepper_1_title: "Twój kalendarz jest już online, zobacz, jak wygląda",
  stepper_1_title_a: "Utwórz swój kalendarz online",
  stepper_1_description:
    "Kliknij poniżej, aby zobaczyć przygotowaną przez nas propozycję kalendarza dla Twoich klientów. Dzięki niemu możesz przyjmować rezerwacje na swoje usługi całkowicie online. To, co widzisz, to tylko przedsmak możliwości Calendesk. Ustawiaj kolory, dodawaj zdjęcia, opisy oraz zakładki w intuicyjnym kreatorze stron www.",
  stepper_1_description_a:
    "Kliknij poniżej, aby utworzyć kalendarz lub stronę www dla Twoich klientów. Dzięki temu przyjmiesz rezerwacje na swoje usługi całkowicie online. Ustawiaj kolory, dodawaj zdjęcia, opisy oraz zakładki w intuicyjnym kreatorze stron www.",
  open_website_button: "Otwórz stronę www",
  edit_website_button: "Edytuj stronę www",
  calendar_url_link: "Adres Twojego kalendarza",

  stepper_2_title: "Kto będzie przyjmować rezerwacje?",
  stepper_2_description:
    "Możesz mieć wiele terminarzy, niezależnie od tego, czy pracujesz samodzielnie, czy zatrudniasz pracowników. Konto pracownika to osobny terminarz spotkań z własnym grafikiem. Utworzyliśmy już pierwszego pracownika za Ciebie. To jest Twoje konto z Twoim grafikiem, wystarczy, że dostosujesz je do siebie.",
  view_employees_account_button: "Zobacz konta pracowników",

  stepper_3_title: "Jakie usługi oferujesz klientom?",
  stepper_3_description:
    "Wymień usługi, które chcesz zaprezentować swoim klientom. Każda z usług może mieć inną konfigurację. Możesz ustalić różne ceny, różne czasy trwania usługi oraz przypisać usługi do różnych pracowników. Stworzyliśmy za Ciebie Twoją pierwszą usługę, wystarczy, że dopasujesz ją do siebie.",
  view_services_button: "Zobacz listę usług",

  stepper_4_title: "Czy będziesz przyjmować płatności online?",
  stepper_4_description:
    "Skorzystaj z wygodnego systemu płatności online i pozwól klientom płacić za Twoje usługi. Płatności w Calendesk w połączeniu z systemem Stripe, to nie tylko przedpłaty od klientów, to zaawansowany operator płatności, który w połączeniu z Calendesk pozwala na znacznie więcej. Konfiguracja trwa kilka minut i jest niezbędna, aby przetwarzać płatności w Calendesk.",
  configure_payments_button: "Skonfiguruj płatności",

  stepper_5_title: "Dodatkowa pomoc",
  stepper_5_description:
    "Potrzebujesz więcej informacji? Masz problem z konfiguracją systemu? Umów się na bezpłatną konsultację z naszym konsultantem lub skorzystaj z Centrum Pomocy, które zawiera przewodniki w formie artykułów i wideo. Jesteśmy dostępni dla Ciebie również na czacie, wystarczy, że klikniesz ikonkę czatu w prawym dolnym rogu.",

  meet_us: "Bezpłatna konsultacja",
  help_center: "Centrum Pomocy",
  finish: "Zakończ",

  go_to_tab: "Przejdź do zakładki",
  with: "z",
  wb_page_only_with_auth: "Strona dostępna tylko dla zalogowanych użytkowników",
  wb_page_only_with_auth_short: "Tylko dla zalogowanych",
  wb_menu_button_only_with_auth:
    "Przycisk będzie widoczny tylko dla zalogowanych użytkowników",
  wb_remind_password_label: "Link przypomnij hasło",
  wb_show_remind_password_link: "Włącz możliwość przypomnienia hasła",
  wb_redirect_after_login: "Przekieruj po zalogowaniu do",
  wb_redirect_after_signup: "Przekieruj po rejestracji do",
  wb_billing_data_checkbox_question: "Dane do faktury VAT",
  wb_gdpr_fields: "Pola RODO",
  wb_dummy_gdpr_field: "Akceptuję Regulamin i Politykę Prywatności",
  wb_add_rgdpr_field_element: "Dodaj pole RODO",
  wb_gdpr_field_name: "Wewnętrzna nazwa pola",
  wb_dummy_gdpr_field_name: "Zgoda RODO",
  wb_enable_discounts: "Włącz możliwość wpisywania kodów rabatowych",
  subscriptions_stripe_account_missing_description:
    "Potrzebujesz w pełni skonfigurowanego konta płatności Stripe, aby używać subskrypcji.",
  optional_field: "Pole opcjonalne",
  select_all: "Zaznacz wszystko",
  unselect_all: "Odznacz wszystko",
  wb_show_billing_data: "Pozwól na uzupełnienie danych do faktury",
  edit_email: "Edytuj e-mail",
  edit_sms: "Edytuj SMS",
  edit_push: "Edytuj Push",
  booking_control_url_example:
    "https://twoja-strona.calendesk.net/bookings/5de239ca-c1e1-4040-743s-195ef52271aa",
  custom_fields_example:
    '<h3 class="mb0">Dodatkowe informacje:</h3><p>Nie mogę się doczekać spotkania!</p>',
  usage: "Użycie",
  insufficient_permissions_error: "Nie masz wystarczających uprawnień.",
  additional_auth_required: "Twój bank wymaga dodatkowej autoryzacji",
  general_payment_error:
    "Wystąpił problem podczas płatności. Być może Twoja karta nie obsługuje subskrypcji, skontaktuj się z bankiem lub wybierz inną metodę płatności. Jeśli potrzebujesz pomocy, napisz do nas na czacie.",
  invoice_data_update_fail:
    "Twoje dane do faktury zostały odrzucone. Upewnij się, że wprowadzony numer NIP i pozostałe dane są poprawne i spróbuj ponownie.",
  wb_section_hidden_info:
    "W zakładce „Ogólne” możesz przywrócić sekcję ponownie.",
  add_to_your_website: "Wstaw kod na stronę",
  add_to_your_website_description:
    "Wybierz element, który chcesz wstawić na swoją stronę www.",
  add_to_your_website_description_widget_1:
    "Wstaw poniższy kod HTML na Twoją stronę www w miejscu, gdzie chcesz, aby Twój kalendarz się pojawił.",
  add_to_your_website_description_widget_2:
    "Wstaw poniższy kod na Twoją stronę www w miejscach, w których chcesz wyświetlić przycisk do otwarcia Twojego kalendarza.",
  add_to_your_website_description_widget_3:
    "Wstaw poniższy kod na Twoją stronę www w miejscach, w których chcesz wyświetlić link do otwarcia Twojego kalendarza.",
  widget_1_title: "Stały element",
  widget_1_description:
    "Twój kalendarz jako stały element strony www. Twoi klienci zobaczą kalendarz od razu.",
  widget_2_title: "Okienko z przycisku",
  widget_2_description:
    "Przycisk u dołu Twojej strony. Twoi klienci zobaczą kalendarz po kliknięciu przycisku.",
  widget_3_title: "Okienko z linku",
  widget_3_description:
    "Dodaj link w formie tekstu do Twojej strony, który otworzy okienko z kalendarzem.",
  widget_dummy_1: "Umów się na wizytę",
  button_text: "Tekst na przycisku",
  link_text: "Tekst linku",
  text_color: "Kolor tekstu",
  background_color: "Kolor tła",
  copy_code_info: "Skopiuj kod i wklej go na Twoją stronę www",
  field_only_for_services: "Pokaż pole formularza tylko dla wybranych usług",
  user_preferences: "Ustawienia",
  service_days_title:
    "Ogranicz dodatkowo możliwość rezerwacji do wybranych dni i godzin (rezerwacje ograniczone przez grafik pracownika oraz dodatkowe ograniczenie godzin)",
  service_days_description:
    "Możesz ograniczyć możliwość rezerwacji tej usługi, tylko do konkretnych dni i godzin w tygodniu. Np. jeśli Twoja usługa ma miejsce tylko w piątki o godz. 19, to pomimo ustawionego grafiku pracownika na cały dzień, klient zobaczy tylko jedną godzinę dostępności. Ta opcja nie jest wymagana i jeśli jej nie ustawisz, to rezerwacje będą wyświetlały się zgodnie z grafikiem pracowników.",
  disconnect_stripe_account: "Rozłącz konto Stripe",
  disconnect_stripe_account_success: "Konto rozłączone poprawnie.",
  connect_current_stripe_account_question: "Posiadam już konto Stripe",
  connect_current_stripe_account_number: "Twój obecny Stripe ID",
  connect_current_stripe_account_number_error:
    "Uzupełnij Stripe ID lub jeśli nie posiadasz konta Stripe, odznacz opcję „Posiadam już konto Stripe”.",
  stripe_connect_account_failed:
    "Wystąpił błąd podczas łączenia konta, jeśli błąd będzie się powtarzać, skontaktuj się z nami na czacie.",
  wb_text_hide: "Ukryj tekst",
  wb_radio_add_option: "Dodaj opcję wyboru",
  wb_form_element_type_title: "Rodzaj pola w formularzu",
  wb_email_subject: "Temat wiadomości e-mail",
  wb_email_form_success_url: "Przekieruj do URL po wysłaniu formularza",
  wb_email_sent: "Treść wiadomości po wysłaniu formularza",
  form: "Formularz",
  wb_section_text: "Tekst w sekcji",
  wb_flexible_form_info:
    "Formularz po wysłaniu trafi na Twoją skrzynkę e-mail zdefiniowaną w panelu Calendesk (zakładka Profil -> Twoja firma). Poniżej możesz ustawić temat wiadomości oraz wybrać pola, które mają znaleźć się w formularzu.",
  wb_section_info: "Skonfiguruj wybraną sekcję, używając poniższych elementów.",
  wb_general_tab_info:
    "Skonfiguruj poniżej ogólne ustawienia strony takie jak język, Google Tag ID, ikona w przeglądarce, opis dla robotów oraz obrazek, który wyświetli się podczas dzielenia się linkiem np. w social mediach. Adresy URL do obrazków możesz skopiować z galerii zdjęć, klikając w podgląd obrazka (na dole obrazka zobaczysz jego adres URL).",
  wb_pages_tab_info:
    "Klikając poniżej na nazwę podstrony, możesz przejść do niej, aby edytować jej sekcje. Jeśli chcesz zmienić jej nazwę lub adres, kliknij ikonę obok nazwy podstrony i wybierz „Edytuj”.",
  buttons: "Przyciski",
  wb_form_label_info: "Etykieta (informacja w polu np. Imię)",
  wb_form_description_info: "Opis (informacja nad polem np. Podaj swoje imię)",
  click_to_edit: "Kliknij, aby edytować",
  unavailable_booking_slots: "Blokady kalendarza",
  all_day: "Chcę ustawić pełne dni",
  all_day_tooltip:
    "Wydarzenia utworzone jako pełne dni zobaczysz na górnym pasku kalendarza oraz w widoku miesięcznym.",
  block_calendar: "Zablokuj kalendarz",
  integrations: "Integracje",
  google_calendar_and_meet: "Kalendarz Google + Google Meet",
  zoom: "Zoom",
  zoom_connect_info:
    "Połącz swoje konto Zoom z Calendesk. Dzięki integracji Zoom z Calendesk oraz odpowiednim ustawieniu lokalizacji dla Twojej usługi, Twoje potwierdzone rezerwacje będą zawierały unikalny link do spotkania dla Ciebie i Twojego klienta.",
  zoom_connect_info_short: "Światowy lider w organizowaniu spotkań online.",
  zoom_connect_button_title: "Połącz Zoom",
  zoom_disconnect_info:
    "Połączenie z Zoom jest aktywne. Kliknij „Rozłącz”, aby rozłączyć.",
  zoom_disconnect_button_title: "Rozłącz Zoom",
  zoom_auth_success_title: "Poprawna autoryzacja Zoom",
  zoom_auth_success_description:
    "Dziękujemy, poprawnie autoryzowałeś konto Zoom. Przejdź do swoich usług, aby wybrać lokalizację spotkań za pomocą Zoom.",
  zoom_auth_fail_description:
    "Wystąpił problem podczas autoryzacji Zoom. Spróbuj ponownie.",
  zoom_disconnect_dialog_info: "Czy na pewno chcesz usunąć integrację z Zoom?",
  zoom_disconnect_success_message:
    "Integracja z Zoom została poprawnie usunięta.",
  settings_alphanumeric_sms_sender_title: "Własna nazwa nadawcy SMS",
  calendesk_name_example: "np. Calendesk",
  settings_alphanumeric_sms_sender_input_title: "Nadawca SMS",
  settings_alphanumeric_sms_sender_validation_error:
    "Błędny format nadawcy. Akceptowane są litery, spacje i cyfry. Minimum jedna litera. Maksymalnie 11 znaków.",
  settings_alphanumeric_sms_sender_description:
    "Twoja własna nazwa nadawcy SMS we wiadomościach do klientów. Zamień nudny i losowy numer telefonu na własną nazwę i pokaż się z najlepszej strony. Nazwa musi zawierać przynajmniej jedną literę. Akceptowane wartości to litery (bez polskich znaków), cyfry oraz spacje. Maksymalnie 11 znaków.",
  enable_package: "Włącz pakiet",
  package_not_available_title:
    "Potrzebujesz dodatkowego pakietu, aby używać tej funkcji. Przejdź do sklepu, aby włączyć pakiet.",
  subscription_update_information:
    "Jeśli zmieniasz plan subskrypcji lub włączasz pakiety dodatkowe w trakcie okresu rozliczeniowego, operator płatności przeliczy kwoty i pobierze od Ciebie tylko wymaganą różnice.",
  wb_only_services_with_subscriptions:
    "Pokaż tylko usługi, dla których zalogowany klient ma aktywne subskrypcje",
  wb_use_booking_cart: "Użyj koszyka rezerwacji",
  wb_selected_services: "Tekst w koszyku",
  wb_count_places_in_dates:
    "Pokazuj wszystkie wolne miejsca przy datach (rezerwacje grupowe)",
  number_of_sms_messages: "Liczba wiadomości SMS",
  api: "API",
  api_description:
    "Nieograniczone możliwości integracji z Calendesk dzięki połączeniu RESTful API. Wykorzystaj interfejs Calendesk API do niestandardowych rozwiązań.",
  generate_api_key_button: "Wygeneruj klucz API",
  delete_api_key: "Usuń klucz API",
  api_key: "Klucz API",
  open_api_documentation: "Dokumentacja API",
  tenant_name: "Tenant name",
  service_limits: "Dodatkowe ograniczenia usługi",
  service_availability_title: "Dostępność usługi dla klientów",
  choose_availability_schedule: "Wybierz grafik dostępności",
  add_new_availability_schedule: "Dodaj nowy grafik",
  update_availability_schedule: "Edytuj grafik dostępności",
  add_schedules_with_plus:
    "Brak grafików. Dodaj nowy grafik, klikając „+” po prawej stronie.'",
  schedule_name: "Nazwa grafiku",
  display_schedule: "Edytuj grafik",
  delete_schedule: "Usuń grafik",
  weekdays: "Dni tygodnia",
  schedule_dates: "Daty nadpisujące dni tygodnia",
  schedule_dates_info:
    "Dodaj daty, dla których dostępność zmieni się w stosunku do godzin tygodniowych",
  add_new_date: "Dodaj datę",
  hours_availability_info: "Godziny dostępności",
  select_availability_dates_info: "1. Wybierz dni dostępności",
  select_availability_hours_info: "2. Wybierz godziny dostępności",
  fill_availability_schedules: "Wybierz dni, które będą dostępne w grafiku",
  plan_unavailable_error:
    "Twoje obecne ustawienia Calendesk nie pozwalają Ci na wybór tego planu. Jeśli Twój okres testowy się skończył, skontaktuj się z nami, abyśmy mogli go wydłużyć.",
  value: "Wartość",
  no_data: "Brak danych",
  created_by: "Utworzono przez",
  updated_by: "Ostatnia zmiana przez",
  updated_at: "Zaktualizowano",
  my_booking: "Moje rezerwacje",
  payment_user_is_required:
    "Brak przypisanego klienta. Do dokonania płatności wymagany jest aktywny klient.",
  promo_number_of_months: "Liczba miesięcy, dla których ważna jest cena:",
  wb_newsletter_success_url: "Newsletter URL — przekierowanie po zapisie",
  wb_account_activation_success_url:
    "Aktywacja konta URL — przekierowanie po aktywacji",
  wb_show_name_surname: "Pokaż imię i nazwisko w formularzu",
  wb_show_phone_number: "Pokaż telefon w formularzu",
  wb_hide_time_zone: "Ukryj możliwość wyboru strefy czasowej",
  wb_add_code_to_website_head:
    "Skopiuj poniższy kod i wklej go do kodu swojej strony www między tagami „<head>” i „</head>”.",
  wb_add_code_to_website_frame:
    "Skopiuj poniższy kod i wklej go do kodu swojej witryny w miejscu, w którym chcesz wyświetlić swój kalendarz.",
  wb_height_desktop: "Wysokość na komputerach w pikselach",
  wb_height_mobile: "Wysokość na smartfonach w pikselach",
  google_calendar_add_title:
    "Wysyłaj rezerwacje i blokady do Kalendarza Google",
  google_calendar_add_info:
    "Wybierz Kalendarz Google, do którego mamy dodawać nowe rezerwacje oraz blokady kalendarza z Calendesk.",
  select_calendar: "Wybierz kalendarz",
  calendar_watch_title: "Pobieraj wydarzenia z kalendarzy",
  google_calendar_watch_info:
    "Wybierz, z których Kalendarzy Google powinniśmy pobierać wydarzenia. W ich miejsce w kalendarzu Calendesk utworzymy blokady, aby wyłączyć Twoją dostępność.",
  select_calendars: "Wybierz kalendarze",
  contact_sales: "Kontakt",
  notification_contains_special_characters:
    "Powiadomienie zawiera znaki specjalne",
  replace_invalid_sms_characters_checkbox:
    "Usuń znaki specjalne w powiadomieniach SMS (może mieć wpływ na mniejszą liczbę wysyłanych SMS)",
  sms_number_of_messages_info:
    "Ostateczna liczba wysłanych wiadomości SMS może się różnić po przeliczeniu długości zmiennych oraz uwzględnieniu opcji „Usuń znaki specjalne w powiadomieniach SMS” w zakładce Ustawienia/Platforma.",
  blackout_date_all_employees_warning:
    "Zablokuj kalendarz u wszystkich pracowników.",
  no_limit: "Bez limitu",
  no_available_time_slots:
    "Brak dostępnych godzin. Spróbuj zmienić datę lub wybrać inną usługę.",
  no_available_time_slots_v2:
    "Brak dostępnych godzin w wybranym dniu. Spróbuj zmienić datę, lokalizację lub wybrać inną usługę.",
  select_availability_service_info:
    "Kliknij wybraną godzinę, aby przenieść ją do formularza rezerwacji.",
  select_service_error: "Wybierz usługę, aby wyszukać terminy.",
  booking_awaiting_payment_warning:
    "Rezerwacja zostanie usunięta, jeśli nie zostanie opłacona. Czas na opłacenie:",
  booking_reminder_setting_info: "Czas wysyłki przed rezerwacją",
  integrations_google_calendar_id_help:
    "Zobacz artykuł „Jak wybrać odpowiedni kalendarz?”",
  booking_date_in_the_past_warning:
    "Próbujesz edytować wydarzenie, którego data już minęła. Powiadomienia e-mail/SMS/push oraz synchronizacja z zewnętrzymi kalendarzami nie będą dostępne.",
  update_draft_name_title: "Zmień nazwę szablonu",
  draft_name: "Nazwa szablonu",
  wb_duplicate_section_title: "Kopiuj sekcję do wybranej podstrony",
  wb_duplicate_section_info:
    "Wybierz podstronę, do której chcesz skopiować sekcję.",
  wb_duplicate_section_success_message: "Sekcja utworzona poprawnie.",
  booking_customer_link: "Link do rezerwacji klienta",
  autocomplete_hint: "Zacznij pisać, aby wyszukać",
  redirect_to_external_url: "Przekieruj na zewnętrzny adres URL",
  url_address: "Adres URL",
  url_example: "np. https://twoja-strona.pl",
  open_in_new_tab: "Otwórz w nowej zakładce",
  push_all_future_events_to_calendar_checkbox:
    "Wyślij teraz wszystkie przyszłe rezerwacje oraz blokady kalendarza, które masz zaplanowane w Calendesk.",
  google_calendar_id: "ID kalendarza Google",
  set_google_calendar_id: "Ustaw ID kalendarza",
  google_calendar_id_info:
    "Jeśli masz kilka kalendarzy Google na jednym koncie, tutaj możesz ustawić numer ID kalendarza, który chcesz wykorzystywać. Jeśli nic nie wpiszesz, użyjemy Twojego głównego kalendarza Google.",
  google_calendar_example: "np. c_8gdd84889nd9nxod4@group.calendar.google.com",
  additional_options: "Dodatkowe opcje",
  calendar_view: "Kalendarz",
  require_billing_data_checkbox: "Wymagaj podania danych do faktury VAT",
  only_company_invoice_data_type_checkbox:
    "Wymagaj pełnych danych do faktury VAT (NIP, nazwa firmy, adres)",
  you_can_edit_admin_password_info: "Nie możesz edytować hasła administratora.",
  can_not_find_booking:
    "Nie można wczytać rezerwacji, została prawdopodobnie usunięta.",
  can_not_find_unavailable_booking_slot:
    "Nie można wczytać blokady kalendarza, została prawdopodobnie usunięta.",
  price_additional_info:
    "Minimalna kwota do przetwarzania płatności to 2 zł (lub równowartość w dolarach $0.5).",
  service_types_additional_info:
    "Dla każdego z wariantów usługi możesz określić inny czas trwania oraz inną cenę.",
  service_settings: "Ustawienia usługi",
  service_employee_title: "Kto będzie wykonywał usługę?",
  service_additional_break_info: "Dodatkowa przerwa przed lub po rezerwacji",
  service_max_people_info: "Włącz możliwość rezerwacji grupowych",
  stripe_payments_warning:
    "Integracja Stripe nie jest jeszcze aktywna. Podłącz Stripe, aby w pełni akceptować płatności.",
  advance_settings_label: "Pokaż ustawienia zaawansowane",
  service_availability_employees_info:
    "Pracownicy używają swoich grafików pracy.",
  service_availability_service_info:
    "Usługa ma jeden grafik dostępności dla wszystkich pracowników.",
  service_availability_employees_schedule_title: "Grafik pracowników",
  service_availability_service_schedule_title: "Grafik usługi",
  service_free_option: "Bezpłatna",
  service_max_people_short_info: "Liczba miejsc",
  required_capability_info:
    "W celu ochrony klientów musimy zweryfikować Twoje konto, aby odblokować tę funkcję. To jest standardowa procedura. Dostaliśmy informację i pracujemy już nad tym, spróbuj za chwilę lub skontaktuj się z nami.",
  required_permission_info:
    "Twoja uprawnienia są niewystarczające, aby dokonać tej operacji. Skontaktuj się z administratorem.",
  old_employee_schedule_label: "Stara wersja grafiku pracy",
  old_employee_schedule_label_tooltip:
    "Przełącz się już teraz na współdzielone grafiki dostępności, które dają znacznie więcej możliwości.",
  employee_schedule_hint: "np. Jan Pracowity - grafik pracy",
  duplicate_service: "Duplikuj usługę",
  show: "Pokaż",
  wb_custom_code: "Skrypty niestandardowe",
  custom_code_info:
    "Skrypty niestandardowe pojawią się tylko na opublikowanej stronie.",
  wb_show_service_column: "Pokaż kolumnę z nazwą usługi",
  wb_require_billing_data: "Wymagaj podania danych do faktury VAT",
  private_notes: "Prywatne notatki",
  signup_onboarding_title_1: "Witaj w Calendesk!",
  signup_onboarding_subtitle_1:
    "5-minutowa konfiguracja teraz, zaoszczędzi Ci wielu godzin pracy później. W kilku prostych krokach przeprowadzimy Cię przez najważniejsze ustawienia Calendesk.",
  signup_onboarding_title_2: "Pracownicy",
  signup_onboarding_subtitle_2:
    "Pracownicy mogą przyjmować rezerwacje online na wybrane usługi oraz mieć osobny dostęp do panelu Calendesk. Każdy z pracowników może mieć swój własny grafik dostępności.",
  signup_onboarding_title_3: "Usługi",
  signup_onboarding_subtitle_3:
    "Usługi pojawią się w kalendarzu rezerwacji. Dodaj teraz przynajmniej jedną usługę z Twojej oferty. Później możesz dodać więcej usług, pogrupować je w kategorie oraz edytować szczegóły.",
  signup_onboarding_title_4: "Dostępność kalendarza",
  signup_onboarding_subtitle_4:
    "Grafik dostępności pilnuje, kiedy jesteś dostępny i kiedy Twoi klienci mogą zapisać się do Ciebie na wizytę. Możesz go przypisać do pracowników lub usług.",
  signup_onboarding_title_5: "Integracje",
  signup_onboarding_subtitle_5:
    "Integrując Calendesk z zewnętrznymi narzędziami, możesz usprawnić swój przepływ pracy i zwiększyć jego wydajność.",
  signup_onboarding_title_6: "Gratulacje!",
  signup_onboarding_subtitle_6:
    "Wszystko gotowe, aby przyjmować rezerwacje od Twoich klientów.",
  signup_onboarding_card_title_1: "O Tobie",
  signup_onboarding_card_subtitle_1: "Dodaj kilka podstawowych informacji.",
  signup_onboarding_card_title_2: "Kto będzie korzystać z Calendesk?",
  signup_onboarding_card_subtitle_2:
    "Dodaj osoby, które zatrudniasz lub z którymi pracujesz.",
  signup_onboarding_card_title_3: "Co będziesz oferować klientom?",
  signup_onboarding_card_subtitle_3:
    "Wpisz nazwę usługi, jej cenę oraz czas trwania. Ustal, kto będzie odpowiedzialny za wykonanie usługi.",
  signup_onboarding_card_title_4: "Domyślny grafik dostępności",
  signup_onboarding_card_subtitle_4:
    "Przypiszemy domyślny grafik do Twoich pracowników. Później bez problemu dostosujesz go szczegółowo, określisz wykluczenia, urlopy czy święta.",
  signup_onboarding_card_title_5: "Dostępne integracje",
  signup_onboarding_card_subtitle_5:
    "Poniżej znajdziesz szybki podgląd dostępnych integracji. Podłączysz je bez problemu w panelu Calendesk.",
  signup_onboarding_card_title_6: "Twój kalendarz jest już online",
  signup_onboarding_card_subtitle_6:
    "Stworzyliśmy propozycję dla Twojego kalendarza rezerwacji. Dzięki Calendesk z łatwością dostosujesz adres URL, kolory, sekcje, podstrony czy zmienisz styl kalendarza.",
  signup_onboarding_services_employees_label: "Kto będzie wykonywać usługę?",
  signup_onboarding_domain_label:
    "Adres URL zmienisz później bez problemu w kreatorze stron www.",
  signup_onboarding_employee_is_public_info:
    "Konto pracownika może przyjmować rezerwacje od klientów, ale może też służyć Twojemu pracownikowi tylko do logowania, aby np. przeglądać kalendarz. Tę opcję możesz bez problemu zmienić później.",
  online_bookings: "Rezerwacje online",
  integrations_category_calendars: "Kalendarze",
  integrations_category_video: "Połączenia wideo",
  integrations_category_payments: "Płatności",
  integrations_category_accounting: "Fakturowanie",
  integrations_category_automation: "Automatyzacja",
  integrations_category_analytics: "Analityka",
  integrations_category_www: "Strona www",
  coming_soon_info:
    "Opcja dostępna wkrótce. Poinformujemy Cię, jak tylko skończymy nad nią prace.",
  go_to_services_button: "Przejdź do usług",
  start_integration_label: "Zacznij integrację",
  integrations_for_employee_tab: "Integracje pracownika",
  integrations_for_global_tab: "Integracje globalne",
  zapier: "Zapier",
  zapier_description:
    "Zapier pozwala wymieniać informacje pomiędzy pozornie niezwiązanymi aplikacjami, dzięki czemu dane z Calendesk możesz przekazać do innych aplikacji i wykonać w nich odpowiednie działania.",
  zapier_description_short:
    "Przekazuj dane z Calendesk do ponad 4000 aplikacji!",
  google_tag_manager: "Google Tag Manager",
  google_tag_manager_description:
    "Zarządzaj wszystkimi integracjami na Twojej stronie www w jednym miejscu. Google Tag Manager to zaawansowane narzędzie, które pozwoli Ci na dodanie dowolnego kawałka kodu do Twojego kalendarza lub strony www w Calendesk.",
  google_tag_manager_description_short:
    "Zarządzaj wszystkimi integracjami na Twojej stronie www w jednym miejscu.",
  calendesk_widget: "Wtyczka Calendesk",
  calendesk_widget_description:
    "Umieść system rezerwacji Calendesk na dowolnej stronie www. Zdecyduj, czy chcesz używać systemu jako element strony, wyskakujące okienko, a może stały, widoczny przycisk u dołu strony? Przejdź do kreatora i skopiuj wygenerowany kod wtyczki. Wstaw go na swojej stronie i pozwól klientom umawiać się bezpośrednio na Twojej stronie www.",
  calendesk_widget_description_short:
    "Umieść system rezerwacji Calendesk na dowolnej stronie www.",
  google_analytics: "Google Analytics",
  google_analytics_description:
    "Google Analytics dostarcza bezpłatne narzędzia potrzebne do analizowania danych dotyczących Twojej firmy oraz strony www w jednym miejscu, dzięki czemu możesz podejmować mądrzejsze decyzje.",
  microsoft_teams: "Teams",
  microsoft_skype: "Skype",
  microsoft_outlook: "Outlook",
  work_schedule: "Grafik pracy",
  category_default_name: "Konsultacje",
  root_email_info: "Główny e-mail zmienisz po konfiguracji.",
  email_must_be_unique: "Adresy e-mail są opcjonalne i nie mogą się powtarzać.",
  value_must_be_unique: "Wpisana wartość nie może się powtarzać.",
  billing_data: "Dane do rozliczeń",
  global_error_title:
    "Przepraszamy, wystąpił problem podczas ładowania aplikacji Calendesk.",
  global_error_subtitle: "Problem ten zwykle występuje z dwóch przyczyn:",
  global_error_description_1:
    "1. <b>Połączenie z internetem</b>. Spróbuj sprawdzić swoje połączenie z internetem i odświeżyć stronę.",
  global_error_description_2:
    "2. <b>Aktualizacja systemu Calendesk</b>. Czasami wprowadzamy aktualizacje systemu, aby usprawnić jego działanie. Spróbuj odświeżyć stronę za kilka minut.",
  main_page_button_title: "Strona główna",
  reload: "Odśwież",
  wb_show_service_description: "Pokaż opis usługi",
  wb_hide_booking_time_to: "Ukryj godzinę zakończenia rezerwacji",
  wb_redirect_logo: "Przekierowanie z logo",
  collapse_all: "Zwiń wszystkie",
  expand_all: "Rozwiń wszytkie",
  service_status: "Status usługi",
  availability: "Grafiki dostępności",
  add_availability_schedule: "Dodaj grafik dostępności",
  availability_list_info:
    "Wykorzystaj grafiki dostępności, aby wyświetlić swoim klientom dostępne terminy rezerwacji. Grafiki mogą być przypisane do pracowników, bezpośrednio do usług lub do obu jednocześnie. Grafiki mogą być współdzielone. Każdy z pracowników może zarządzać grafikiem pracy, jeśli jest do niego przypisany lub sam go utworzył.",
  assigned_to_employees: "Przypisany do pracowników",
  assigned_to_services: "Przypisany do usług",
  assign_to_employees: "Przypisz do pracowników",
  assign_to_services: "Przypisz do usług",
  activate_work_schedule: "Przypisz do mnie",
  section_admin_availability_list: "Panel / Grafiki dostępności",
  test_availability: "Testuj dostępność",
  employee_assigned_to_services_title: "Usługi wykonywane przez pracownika",
  employee_assigned_to_services_info:
    "Rezerwacja na usługę wymaga przypisania pracownika, który ją wykona. Poniżej możesz wybrać usługi, które powinny być przypisane do tego pracownika.",
  wb_column_width: "Szerokość kolumny w procentach np. 50",
  max_booking_time: "Rezerwacja usługi najwcześniej",
  max_user_bookings: "Maksymalna ilość rezerwacji usługi dla jednego klienta",
  max_user_bookings_checkbox: "Ogranicz ilość rezerwacji usługi dla klientów",
  create_user_subscription_button_title: "Przypisz subskrypcję",
  create_user_subscription_info_1:
    "Możesz utworzyć dowolny rodzaj subskrypcji bez angażowania klientów w ich zakup. Subskrypcje odnawialne z limitami na rezerwacje, będą odnawiać swoje limity zgodnie z ich ustawieniami. Subskrypcje te będą aktywne do momentu ich ręcznego anulowania. W przypadku subskrypcji jednorazowych (pakietów), będą one aktywne tak długo, aż klient wykorzysta ich zasoby lub zostaną ręcznie anulowane. System nie pilnuje płatności do subskrypcji/pakietów ręcznych i nie anuluje takich subskrypcji w przypadku braku płatności. Płatnościami za subskrypcje ręczne możesz zarządzać w zakładce Klienci/Subskrypcje. Poniżej możesz również utworzyć pierwszą płatność za subskrypcję.",
  create_user_subscription_info_2:
    "Wybierz subskrypcję, którą chcesz przypisać klientowi. Możesz określić, datę utworzenia (sprzedaży) oraz zakończenia subskrypcji. Data utworzenia subskrypcji musi być datą przeszłą. Opcjonalnie dodaj informacje o płatności, jeśli miała ona miejsce poza systemem Calendesk.",
  create_subscription_payment: "Chcę utworzyć płatność",
  create_payment: "Utwórz płatność",
  user_subscription_id: "ID subskrypcji klienta",
  booking_id: "ID rezerwacji",
  create_payment_for_user_subscription:
    "Utwórz płatność dla subskrypcji klienta",
  create_payment_invoice_info:
    "Faktura VAT zostanie automatycznie wystawiona, jeśli masz prawidłowo skonfigurowaną integrację z systemem fakturowym i zaznaczono opcję umożliwiającą wystawianie faktur VAT po otrzymaniu płatności.",
  notification_usage_not_available_info:
    "Nie masz wystarczającej liczby powiadomień, aby wysłać wiadomości. Przejdź do zakładki „Powiadomienia”, aby uzupełnić konto.",
  buy_notifications: "Uzupełnij powiadomienia",
  notifications_sent: "Wiadomość została wysłana.",
  employee_names: "Imię i nazwisko pracownika",
  pay_with_subscription_error_message:
    "Nie możesz zapłacić wybraną subskrypcją - jej limity są wyczerpane, jest nieaktywna lub jej data utworzenia jest po dacie rezerwacji.",
  subscription_created_at: "Data utworzenia subskrypcji",
  subscription_ends_at: "Data ważności subskrypcji",
  wants_create_subscription_end_date_checkbox:
    "Chcę ustawić datę ważności subskrypcji",
  manage: "Zarządzaj",
  status_and_payment: "Status/Płatność",
  duplicate_booking: "Duplikuj rezerwację",
  edit_booking: "Edytuj rezerwację",
  edit_unavailable_booking_slot: "Edytuj blokadę",
  delete_unavailable_booking_slot: "Usuń blokadę",
  disable_indexing_by_robots:
    "Nie zezwalaj robotom wyszukiwarek na indeksowanie strony zakupu tej subskrypcji",
  show_employees_working_hours:
    "Pokazuj grafik dostępności pracowników w kalendarzu",
  integrations_category_employees:
    "Synchronizacja kalendarzy oraz połączenia wideo",
  integrations_google_tools_title: "Pakiet narzędzi od Google",
  integrations_microsoft_tools_title: "Pakiet narzędzi od Microsoft",
  outlook_calendar_connect_button_title: "Rozpocznij integrację z Microsoft",
  google_calendar_connect_button_title: "Rozpocznij integrację z Google",
  google_calendar_description:
    "Dostęp do Twoich rezerwacji i blokad Calendesk zawsze pod ręką w Twoim personalnym kalendarzu Google. Zdecyduj, które kalendarze Google mamy obserwować, aby automatycznie blokować Twój kalendarz dostępności dla klientów w Calendesk.",
  outlook_calendar_description:
    "Dostęp do Twoich rezerwacji i blokad Calendesk zawsze pod ręką w Twoim personalnym kalendarzu Microsoft Outlook. Zdecyduj, które kalendarze Outlook mamy obserwować, aby automatycznie blokować Twój kalendarz dostępności dla klientów w Calendesk.",
  microsoft_teams_description:
    "Ustaw lokalizację Twojej usługi w Calendesk na Teams, aby podczas każdej rezerwacji, system Calendesk tworzył unikalny link do spotkania. Link trafi do Ciebie oraz Twojego klienta zaraz po utworzeniu rezerwacji.",
  microsoft_skype_description:
    "Ustaw lokalizację Twojej usługi w Calendesk na Skype, aby podczas każdej rezerwacji, system Calendesk tworzył unikalny link do spotkania. Link trafi do Ciebie oraz Twojego klienta zaraz po utworzeniu rezerwacji.",
  whatsapp: "WhatsApp",
  whatsapp_description:
    "Połącz swój numer WhatsApp z Calendesk. Dzięki integracji WhatsApp z Calendesk oraz odpowiednim ustawieniu lokalizacji dla Twojej usługi Twoje potwierdzone rezerwacje będą zawierały unikalny link do spotkania dla Ciebie i Twojego klienta.",
  whatsapp_description_short:
    "Szybkie, proste i bezpieczne dzwonienie i przesyłanie wiadomości dostępne na telefonach na całym świecie.",
  whatsapp_connect_button_title: "Połącz WhatsApp",
  calendar_public_label: "Pokazuj prywatne opisy wydarzeń po ich pobraniu",
  calendar_public_tooltip:
    "Calendesk może wyświetlać opisy prywatnych wydarzeń z obserwowanych kalendarzy. Opisy będą widoczne dla wszystkich Twoich współpracowników, którzy mają dostęp do Twoich rezerwacji i blokad w kalendarzu. Zmiana tej opcji dotyczy tylko przyszłych, pobranych wydarzeń.",
  outlook_calendar_add_title:
    "Wysyłaj rezerwacje i blokady do Kalendarza Outlook",
  outlook_calendar_add_info:
    "Wybierz Kalendarz Outlook, do którego mamy dodawać nowe rezerwacje oraz blokady kalendarza z Calendesk.",
  outlook_calendar_watch_info:
    "Wybierz, z których Kalendarzy Outlook powinniśmy pobierać wydarzenia. W ich miejsce w kalendarzu Calendesk utworzymy blokady, aby wyłączyć Twoją dostępność.",
  outlook_calendar_auth_success_title: "Poprawna autoryzacja Microsoft",
  outlook_calendar_disconnect_dialog_title: "Rozłącz kalendarz Outlook",
  outlook_calendar_disconnect_dialog_info:
    "Czy na pewno chcesz rozłączyć kalendarz Outlook i powiązane narzędzia? Twoje przyszłe wizyty i blokady w Calendesk nie będą już wysyłane do kalendarza Outlook.",
  outlook_calendar_disconnect_success_message:
    "Kalendarz został poprawnie rozłączony.",
  microsoft_supported_tools: "Wspierane integracje dla Twojego konta Microsoft",
  whatsapp_add_title: "Twój numer WhatsApp",
  whatsapp_add_info:
    "Podaj numer telefonu, który chcesz wykorzystywać do komunikacji WhatsApp z klientami. Numer będzie przypisany tylko do Twojego konta Calendesk.",
  whatsapp_disconnect_dialog_title: "Rozłącz numer WhatsApp",
  whatsapp_calendar_disconnect_dialog_info:
    "Czy na pewno chcesz rozłączyć integrację z WhatsApp? Twoje przyszłe wizyty w Calendesk nie będą już łączone z tym numerem WhatsApp.",
  whatsapp_calendar_disconnect_success_message:
    "WhatsApp został poprawnie rozłączony.",
  recurrence_event: "Cykliczna",
  google_services_description_short:
    "Dostęp do rezerwacji w Twoim kalendarzu Google. Wykorzystaj Google Meet do połączeń wideo z klientami.",
  microsoft_services_description_short:
    "Wysyłaj rezerwacje i blokady do Kalendarza Outlook. Łącz się z klientami za pomocą Microsoft Teams lub Skype.",
  notifications_running_out_of_notifications:
    "Twoje powiadomienia e-mail/SMS są na wyczerpaniu, doładuj je, aby na bieżąco informować swoich klientów o rezerwacjach.",
  choose_email_notifications_package: "Wybierz pakiet powiadomień e-mail",
  choose_sms_notifications_package: "Wybierz pakiet powiadomień SMS",
  notifications_packages_info:
    "Pakiety powiadomień nie mają terminu ważności i nie przepadają. Możesz je wykorzystywać tak długo, jak długo korzystasz z Calendesk. Powiadomienia dodatkowe są wykorzystywane, gdy skończą Ci się te, które otrzymujesz co miesiąc w ramach swojego planu.",
  never_expires: "Ważne bezterminowo",
  email_package: "Pakiet e-mail",
  sms_package: "Pakiet SMS",
  email_packages: "Pakiety e-mail",
  sms_packages: "Pakiety SMS",
  extra_notification: "powiadomień gratis",
  go_to_notifications: "Przejdź do powiadomień",
  days: "Dni",
  hours: "Godziny",
  hour: "Godzina",
  minutes_full: "Minuty",
  seconds: "Sekundy",
  black_friday_title: "Black Friday 2022!",
  black_friday_subtitle: "Nie zapomnij o swoich klientach!",
  black_friday_ends_info: "Do końca promocji pozostało",
  black_friday_info_1:
    "Przygotowaliśmy wyjątkową promocję na powiadomienia e-mail oraz SMS! Odbierz ekstra powiadomienia przy zakupie pakietów e-mail lub SMS.",
  black_friday_info_2:
    "Wykorzystaj możliwość wysyłania powiadomień e-mail oraz SMS do swoich klientów i przygotuj dla nich coś extra!",
  show_canceled_bookings: "Pokazuj anulowane wizyty w kalendarzu",
  upselling_title: "Dodatkowa sprzedaż",
  upselling_info:
    "Zwiększ przychody oraz zadowolenie klientów poprzez oferowanie dodatkowych produktów lub usług. Możesz wybrać produkty, które będą dodatkowo oferowane podczas rezerwacji usługi. To świetny sposób na tzw. upselling, czyli sprzedaż dodatkową w momencie, kiedy Twój klient decyduje się na rezerwację usługi.",
  upselling_info_2:
    "Funkcja „Produkty” (sprzedaż dodatkowa, upselling) może przynieść wiele korzyści dla Twojego biznesu podczas procesu rezerwacji. Poprzez oferowanie dodatkowych produktów lub usług, możesz zwiększyć zarówno przychody, jak i zadowolenie klientów. Wybrane produkty możesz przypisać do określonych usług, edytując je w zakładce „Oferta/Usługi”.",
  simple_store_products: "Produkty",
  add_simple_store_products_with_plus:
    "Brak produktów. Dodaj produkt, klikając „+” po prawej stronie.",
  product_has_transactions_delete_error:
    "Nie możesz usunąć produktu, który został już sprzedany i posiada transakcje sprzedaży.",
  additional_information: "Dodatkowe informacje",
  simple_product_name_example: "Przykładowa nazwa produktu",
  simple_product_description_example: "Produkt odbierzesz podczas wizyty.",
  simple_store_product_description_title: "Dodatkowe informacje po zakupie",
  simple_store_product_description_info:
    "Dodatkowe informacje, które zostaną przekazane po zakupie produktu w powiadomieniu e-mail.",
  pay_for_products: "Zapłać za produkty",
  add_transaction: "Dodaj transakcję",
  additional_products: "Dodatkowe produkty",
  christmas_wishes_title: "Spokojnych Świąt Bożego Narodzenia 🎅🌟",
  christmas_wishes_subtitle_1:
    "Święta Bożego Narodzenia i Nowy Rok to wyjątkowy czas, dlatego życzymy Ci nie tylko radości i szczęścia, ale także wszelkiej pomyślności i spełnienia marzeń. Mamy nadzieję, że spędzisz ten czas w gronie najbliższych i będziesz cieszyć się każdą chwilą. Niech Nowy Rok przyniesie Ci wiele radości i satysfakcji z osiągnięć.",
  christmas_wishes_subtitle_2: "Zespół Calendesk 🤗",
  user_requested_employee_title: "Klient dokonał wyboru pracownika.",
  subscription_renews_info: "Twój plan odnowi się",
  product_active: "Produkt aktywny",
  simple_store_product_additional_information_title:
    "Dodatkowe informacje przed zakupem",
  simple_store_product_additional_information_description:
    "Dodatkowe informacje, które zostaną wyświetlone w trakcie zakupu produktu, w sytuacji, kiedy produkt kupowany jest niezależnie (poza systemem rezerwacji).",
  upselling_description_title: "Dodatkowy tekst",
  upselling_description_subtitle:
    "Dodatkowy tekst, który wyświetlimy podczas rezerwacji usługi. Możesz wykorzystać go np. do promocji produktów dodatkowych.",
  wb_init_calendar_title_example:
    "Wybierz usługę, aby przejść do kalendarza rezerwacji 👇",
  wb_init_calendar_title: "Tytuł podczas wyboru usługi",
  wb_initial_service_selection: "Pokaż stronę z możliwością wyboru usług",
  add_to_google_calendar_button: "Dodaj do Google Calendar",
  add_to_google_ical_outlook_button: "Dodaj do iCal/Outlook",
  add_to_calendar_variable_title: "Przyciski „Dodaj do kalendarza”",
  manage_booking_button_title: "Zarządzaj rezerwacją",
  manage_bookings_button_title: "Zarządzaj rezerwacjami",
  manage_booking_button_variable_title: "Przycisk „Zarządzaj rezerwacją”",
  manage_bookings_button_variable_title: "Przycisk „Zarządzaj rezerwacją”",
  wb_language_not_supported_info:
    "Przepraszamy, ale wybrany język nie jest w pełni obsługiwany przez kreator stron www Calendesk. Mimo to Twoi klienci zobaczą przetłumaczone elementy, które nie mogą być przez Ciebie zmienione.",
  group_booking_title: "Rezerwacja grupowa",
  edit_group_booking_title: "Edycja rezerwacji grupowej",
  edit_single_instance_group_booking_title:
    "Edycja pojedynczej rezerwacji z grupy",
  group_booking_description:
    "Jeden pracownik i wielu klientów podczas spotkania",
  edit_booking_title: "Edycja rezerwacji",
  single_booking_description:
    "Jeden pracownik i jeden klient podczas spotkania",
  blackout_date_title: "Blokada kalendarza",
  edit_unavailable_booking_slot_title: "Edycja blokady kalendarza",
  blackout_date_description:
    "Zablokuj możliwość dokonywania rezerwacji przez klientów",
  select_service_from_list_title: "Wybierz usługę",
  select_services_title: "Wybierz usługi",
  availability_window_button_title: "Zobacz dostępność pracowników",
  booking_form_service_question:
    "Na jaką usługę chcesz zarezerwować spotkanie?",
  booking_form_employee_question: "Kto ma przeprowadzić spotkanie?",
  booking_form_user_question: "Z kim odbędzie się spotkanie?",
  booking_form_date_question: "Kiedy ma się odbyć spotkanie?",
  booking_location_question: "Gdzie ma się odbyć spotkanie?",

  booking_form_additional_question: "Dodatkowe ustawienia",
  blackout_date_form_date_question: "Kiedy chcesz zablokować kalendarz?",
  blackout_date_form_employee_question: "U kogo chcesz zablokować kalendarz?",
  available_hours_title:
    "Najbliższe dostępne terminy wybranego dnia u pracowników (@{date}):",
  update_recurring_event_title: "Edycja cyklicznego wydarzenia",
  update_recurring_event_question:
    "To wydarzenie jest wydarzeniem cyklicznym. Co chcesz edytować?",
  event_updated_no_notifications_label: "Nie informuj o zmianie wizyty",
  type: "Typ",
  event_type: "Typ wydarzenia",
  event_type_booking: "Rezerwacja",
  event_type_group_booking: "Grupowa rezerwacja",
  event_type_unavailable_booking_slot: "Blokada kalendarza",
  calendesk: "Calendesk",
  blackout_date_default_value: "Blokada kalendarza Calendesk",
  booking_recurrence_description:
    "co trzeci dzień, zaczynając od 10.06.2023, 10 razy",
  manage_booking_event: "Rezerwacja",
  manage_unavailable_booking_slot_event: "Blokada kalendarza",
  wants_set_end_time_label: "Chcę ustawić koniec spotkania ręcznie",
  wants_set_end_time_info:
    "Wybierz opcję ręcznego ustawienia czasu zakończenia spotkania zamiast standardowej długości usługi.",
  new_booking_title: "Nowa rezerwacja",
  new_unavailable_booking_slot_title: "Nowa blokada kalendarza",
  show_more_employees: "Pokaż kolejnych (@{number})",
  select_employee_label: "Wybierz pracownika",
  select_employees_label: "Wybierz pracowników",
  select_customer_label: "Wybierz klienta",
  select_customers_label: "Wybierz klientów",
  booking_from_group: "Rezerwacja z grupy",
  bookings_in_group: "Rezerwacje w grupie",
  event_preview_type_booking: "Podgląd rezerwacji",
  event_preview_type_unavailable_booking_slot: "Podgląd blokady kalendarza",
  event_preview_type_group_booking: "Podgląd rezerwacji grupowej",
  event_history_title: "Historia zmian",
  events: "Wydarzenia",
  download_bookings_csv_title: "Pobierz CSV (Rezerwacje)",
  download_unavailable_booking_slots_csv_title:
    "Pobierz CSV (Blokady kalendarza)",
  employee_is_public_filter_title: "Rezerwacje od klientów",
  employee_is_public_filter_visible: "Przyjmuje rezerwacje",
  employee_is_public_filter_hidden: "Nie przyjmuje rezerwacji",
  wb_calendar_min_date: "Wyświetlaj dostępność od dnia",
  wb_calendar_max_date: "Wyświetlaj dostępność do dnia",
  delete_user_subscriptions: "Usuń subskrypcje klientów",
  cancel_user_subscriptions: "Anuluj subskrypcje klientów",
  payment_date: "Data płatności",
  active_subscriptions: "Aktywne subskrypcje",
  inactive_subscriptions: "Niektywne subskrypcje",
  newsletter_new_record_info:
    "Na podany adres e-mail wyślemy wiadomość, dzięki której użytkownik będzie mógł go potwierdzić.",
  google_calendar_permissions_error:
    "Calendesk nie posiada pełnego dostępu do listy Twoich kalendarzy. Możliwe, że odznaczyłeś wymagane uprawnienia podczas integracji z Google Calendar. Spróbuj odłączyć i ponownie połączyć integrację. Jeżeli nie chcesz udostępnić nam listy swoich kalendarzy, wprowadź ręcznie unikalny numer ID kalendarza.",
  description_too_long: "Wprowadzony opis jest za długi",
  identity_number_label: "Numer wewnętrzny",
  sales: "Sprzedaż",
  offer: "Oferta",
  tools: "Narzędzia",
  activity: "Aktywność",
  billing: "Rozliczenia",
  notification_activity_info:
    "Tutaj znajdziesz historię wysłanych wiadomości e-mail, SMS lub push do swoich klientów oraz pracowników.",
  root_account_title: "Konto główne",
  blackout_date_move_to_another_resource_error:
    "Nie możesz zmienić blokady kalendarza, przypisując ją do innego pracownika. Spróbuj usunąć blokadę i dodać ją ponownie.",
  cancel_group_bookings_title: "Anuluj całą grupę",
  delete_group_bookings_title: "Usuń całą grupę",
  duplicate_group_bookings_title: "Duplikuj całą grupę",
  logs_title: "Dziennik aktywności",
  logs_type_title: "Typ zmiany",
  logs_diff_before_title: "Zmiana przed",
  logs_diff_after_title: "Zmiana po",
  logs_diff_name: "Imię/Nazwa",
  logs_diff_surname: "Nazwisko",
  logs_diff_email: "E-mail",
  logs_diff_status: "Status",
  logs_diff_date_of_birth: "Data urodzenia",
  logs_diff_gender: "Płeć",
  logs_diff_identity_number: "Numer wewnętrzny",
  logs_diff_firebase_token: "Token wewnetrzny (PUSH)",
  logs_diff_draft_uuid: "ID wersji roboczej",
  logs_diff_stripe_id: "ID Stripe",
  logs_diff_fakturownia_id: "ID Fakturownia",
  logs_diff_pm_type: "Typ karty",
  logs_diff_pm_last_four: "Ostatnie cztery cyfry karty",
  logs_diff_payment_method_id: "ID metody płatności",
  logs_diff_gdpr_fields: "Pola RODO",
  logs_diff_email_verified_at: "Weryfikacja e-mail",
  logs_diff_date_of_names_day: "Data imienin",
  logs_diff_card_number: "Numer karty",
  logs_diff_description: "Opis",
  logs_diff_start_date: "Data rozpoczęcia",
  logs_diff_end_date: "Data zakończenia",
  logs_diff_start_time: "Godzina rozpoczęcia",
  logs_diff_end_time: "Godzina zakończenia",
  logs_diff_control: "Numer kontrolny",
  logs_diff_custom_fields: "Niestandardowe pola",
  logs_diff_google_calendar_event_id: "ID wydarzenia w kalendarzu Google",
  logs_diff_google_meet_url: "URL spotkania Google Meet",
  logs_diff_paid: "Płatność",
  logs_diff_skip_create_notifications: "Pomiń powiadomienia o utworzeniu",
  logs_diff_skip_update_notifications: "Pomiń powiadomienia o aktualizacji",
  logs_diff_skip_delete_notifications: "Pomiń powiadomienia o usuwaniu",
  logs_diff_group_id: "ID grupy",
  logs_diff_service_location_id: "ID lokalizacji",
  logs_diff_paid_with_user_subscription_id:
    "Zapłacono z ID subskrypcji użytkownika",
  logs_diff_recurrence_params: "Parametry powtarzania",
  logs_diff_payment_method: "Metoda płatności",
  logs_diff_service_id: "ID usługi",
  logs_diff_service_type_id: "ID typu usługi",
  logs_diff_employee_id: "ID pracownika",
  logs_diff_user_id: "ID użytkownika",
  logs_diff_multi_slot_group_id: "ID rezerwacji grupowej",
  logs_diff_zoom_meeting_id: "ID spotkania Zoom",
  logs_diff_zoom_start_url: "URL początkowy Zoom",
  logs_diff_zoom_join_url: "URL dołączenia do Zoom",
  logs_diff_created_by: "Utworzony przez",
  logs_diff_updated_by: "Zaktualizowany przez",
  logs_diff_customer_time_zone: "Strefa czasowa klienta",
  logs_diff_zoom_occurrence_id: "ID pojedynczy Zoom",
  logs_diff_customer_time_notation: "Typ czasu klienta",
  logs_diff_outlook_calendar_event_id: "ID wydarzenia w kalendarzu Outlook",
  logs_diff_booking_payment_transaction_id:
    "ID transakcji płatności za rezerwację",
  logs_diff_teams_url: "URL Teams",
  logs_diff_skype_url: "URL Skype",
  logs_diff_employee_whatsapp_url: "URL WhatsApp pracownika",
  logs_diff_customer_whatsapp_url: "URL WhatsApp klienta",
  logs_diff_image_id: "ID obrazka",
  logs_diff_data: "Dane",
  logs_diff_success_url: "URL sukcesu",
  logs_diff_recurring_interval: "Powtarzaj cyklicznie",
  logs_diff_service_limit: "Limit usługi",
  logs_diff_tax: "Podatek",
  logs_diff_price: "Cena",
  logs_diff_quantity: "Liczba",
  logs_diff_additional_information: "Dodatkowe informacje",
  logs_diff_rules: "Zasady",
  logs_diff_canceled_at: "Data anulowania",
  logs_diff_wants_invoice: "Faktura",
  logs_diff_cancel_url: "URL anulowania/błędu",
  logs_diff_require_billing_data: "Wymagaj podania danych do faktury VAT",
  logs_diff_disable_robot_indexing:
    "Nie zezwalaj robotom wyszukiwarek na indeksowanie tej subskrypcji",
  logs_diff_only_company_invoice_data_type:
    "Wymagaj pełnych danych do faktury VAT (NIP, nazwa firmy, adres)",
  logs_diff_stripe_subscription_id: "Stripe ID subskrypcji",
  logs_type_created: "Utworzenie",
  logs_type_updated: "Zmiana",
  logs_type_deleted: "Usunięcie",
  logs_subject_type: "Typ obiektu",
  logs_subject_type_user: "Użytkownik",
  logs_subject_type_booking: "Rezerwacja",
  logs_subject_type_subscription: "Subskrypcja",
  logs_subject_type_employee: "Pracownik",
  logs_subject_type_unavailablebookingslot: "Blokada kalendarza",
  logs_subject_type_service: "Usługa",
  logs_subject_type_servicetype: "Wariant",
  logs_subject_type_servicelocation: "Powiązanie lokalizacji z usługą",
  logs_subject_type_serviceemployee: "Powiązanie pracownika z usługą",
  logs_subject_type_servicesimplestoreproduct: "Powiązanie produktu z usługą",
  logs_subject_type_serviceresource: "Powiązanie zasobu z usługą",
  logs_subject_type_bookingtag: "Powiązanie etykiety z rezerwacją",
  logs_subject_type_servicesubscription: "Powiązanie subskrypcji z usługą",
  logs_subject_type_bookingsimplestoreproduct:
    "Powiązanie produktu z rezerwacją",
  logs_subject_type_usergroup: "Powiązanie grupy z użytkownikiem",
  logs_subject_type_category: "Kategoria",
  logs_subject_type_userpreferences: "Preferencje użytkownika",
  logs_subject_type_employeeworkinghour: "Godziny pracy pracownika",
  logs_subject_type_group: "Grupa",
  logs_subject_type_permission: "Uprawnienie",
  logs_subject_type_role: "Rola",
  logs_subject_type_subscriptionpaymenttransaction: "Płatność za subskrypcję",
  logs_subject_type_simplestoreproducttransaction: "Płatność za produkt",
  logs_subject_type_bookingpaymenttransaction: "Płatność za rezerwację",
  logs_subject_type_usersubscription: "Subskrypcja użytkownika",
  logs_subject_type_apikey: "Klucz API",
  logs_subject_type_availabilityschedule: "Grafik dostępności",
  logs_subject_type_tag: "Etykieta",
  logs_subject_type_employeewatchedgooglecalendar: "Kalendarz Google",
  logs_subject_type_employeewatchedoutlookcalendar: "Kalendarz Outlook",
  logs_subject_type_resource: "Zasób",
  logs_subject_type_product: "Produkt",
  logs_subject_type_settings: "Ustawienia",
  logs_subject_type_simplestoreproduct: "Produkt",
  logs_subject_type_address: "Adres",
  logs_subject_type_phone: "Telefon",
  logs_subject_type_templatesettings: "Ustawienia szablonów",
  logs_subject_type_location: "Lokalizacja",
  logs_changer_name: "Zmieniający",
  employee_not_available_in_this_service_error:
    "Pracownik nie jest przypisany do tej usługi.",
  logs_diff_booking_id: "ID rezerwacji",
  logs_diff_transaction_id: "Numer transakcji / komentarz",
  logs_diff_provider_tag: "Metoda",
  logs_diff_paid_at: "Zapłacono",
  logs_diff_promo_code: "Kod rabatowy",
  logs_diff_currency: "Waluta",
  logs_diff_email_notification_booking_created:
    "Potwierdzenie rezerwacji - e-mail",
  logs_diff_email_notification_booking_updated: "Zmiana rezerwacji - e-mail",
  logs_diff_email_notification_booking_deleted: "Usunięcie rezerwacji - e-mail",
  logs_diff_email_notification_booking_reminder:
    "Przypomnienie o rezerwacji - e-mail",
  logs_diff_sms_notification_booking_created: "Potwierdzenie rezerwacji - SMS",
  logs_diff_sms_notification_booking_updated: "Zmiana rezerwacji - SMS",
  logs_diff_sms_notification_booking_deleted: "Usunięcie rezerwacji - SMS",
  logs_diff_sms_notification_booking_reminder:
    "Przypomnienie o rezerwacji - SMS",
  logs_diff_model_id: "Powiązanie z ID",
  logs_diff_e164: "Telefon",
  logs_diff_street: "Ulica",
  logs_diff_postal_code: "Kod pocztowy",
  logs_diff_city: "Miejscowość",
  logs_diff_country_iso_code: "Kraj",
  logs_diff_vat_iso_prefix: "NIP prefix",
  logs_diff_tax_number: "NIP",
  logs_diff_category_id: "ID kategorii",
  logs_diff_duration: "Czas trwania",
  logs_diff_booking_time_before: "Rezerwacja usługi najpóźniej",
  logs_diff_cancel_time_before: "Anulacja terminu usługi najpóźniej",
  logs_diff_change_time_before: "Zmiana terminu usługi najpóźniej",
  logs_diff_max_people: "Liczba miejsc",
  logs_diff_slots_every: "Pokaż klientom możliwość rezerwacji co",
  logs_diff_allow_online_payment: "Włącz płatności online dla tej usługi",
  logs_diff_awaiting_booking_payment_time: "Czas na opłacenie rezerwacji",
  logs_diff_payment_required: "Przedpłata",
  logs_diff_order_position: "Pozycja",
  logs_diff_max_booking_time: "Rezerwacja usługi najwcześniej",
  logs_diff_upselling_description: "Dodatkowy tekst",
  logs_diff_type: "Typ",
  logs_diff_interval_count: "Powtarzaj co",
  logs_diff_color: "Kolor",
  logs_diff_is_public: "Przyjmuje rezerwacje",
  logs_diff_title: "Stanowisko",
  logs_diff_facebook: "Link do profilu na Facebooku",
  logs_diff_instagram: "Link do profilu na Instagramie",
  logs_diff_youtube: "Link do profilu na Youtube",
  logs_diff_twitter: "Link do profilu na Twitterze",
  logs_diff_availability_schedule_id: "ID grafiku dostępności",
  logs_diff_fakturownia_invoice_id: "Fakturownia ID",
  logs_diff_invoice_number: "Numer faktury",
  logs_diff_user_subscription_id: "ID subskrypcji klienta",
  logs_diff_uuid: "ID",
  logs_diff_id: "ID",
  logs_diff_tag_id: "ID etykiety",
  logs_diff_timezone: "Strefa czasowa",
  logs_diff_google_calendar_enabled: "Włączony kalendarz Google",
  logs_diff_zoom_user_email: "Email użytkownika Zoom",
  logs_diff_google_calendar_id: "ID kalendarza Google",
  logs_diff_teams_business_enabled: "Włączony Teams dla firm",
  logs_diff_skype_business_enabled: "Włączony Skype dla firm",
  logs_diff_skype_consumer_enabled: "Włączony Skype dla konsumentów",
  logs_diff_outlook_enabled: "Włączony Outlook",
  logs_diff_outlook_calendar_id: "ID kalendarza Outlook",
  logs_diff_is_google_calendar_public: "Opisy kalendarza Google są widoczne",
  logs_diff_is_outlook_public: "Opisy kalendarza Outlook są widoczne",
  logs_diff_whatsapp_number: "Numer WhatsApp",
  logs_diff_break_time_before: "Czas przerwy przed",
  logs_diff_break_time_after: "Czas przerwy po",
  logs_diff_only_subscriptions: "Tylko subskrypcje",
  logs_diff_max_user_bookings: "Maksymalna liczba rezerwacji użytkownika",
  logs_diff_simple_store_product_id: "ID produktu",
  logs_diff_resource_id: "ID zasobu",
  logs_diff_subject: "Tytuł",
  logs_diff_content: "Treść",
  logs_diff_type_id: "ID typu",
  subject_id: "Numer ID",
  logs_diff_subscription_id: "ID subskrypcji",
  logs_subject_type_notificationtemplate: "Szablony powiadomień",
  no_integration_employee_warning:
    "Integracje wybranego pracownika nie pozwalają przeprowadzić spotkania w wybranej lokalizacji. Link do spotkania nie zostanie wygenerowany. Zmień lokalizację lub wybierz innego pracownika.",
  single_booking_in_group_edit_warning:
    "Edytujesz pojedynczą rezerwację, która jest przypisana do grupy, która ma ustawione cykliczne spotkania. W takim przypadku nie możesz zmienić usługi, czasu oraz pracownika. Jeśli rezerwacja jest nieaktualna, anuluj ją i dodaj ponownie z właściwymi ustawieniami. Jeśli chcesz zmienić ustawienia dla całej grupy, przejdź do edycji grupowej.",
  group_booking_update_unavailable_in_calendar_view:
    "Edycja rezerwacji grupowej poprzez przeciąganie i upuszczanie nie jest możliwa. Aby dokonać edycji, kliknij w rezerwację.",
  event_deleting_title: "Usuwanie wydarzeń",
  event_deleting_question: "Czy na pewno chcesz usunąć wybrane wydarzenie/a?",
  do_not_group_booking_checkbox_label: "Nie grupuj rezerwacji",
  employee_limit_dialog_info:
    "Twój obecny plan nie umożliwia dodania nowego pracownika. Aby utworzyć nowe konto, skontaktuj się z nami poprzez czat lub mailowo pod adresem:",
  tenant_account_frozen_error:
    "Twoje konto było nieaktywne przez długi czas. Przywracamy Twoje dane. Prosimy spróbować ponownie za kilka minut.",
  client_website_bookings_tab: "Zakładka „Rezerwacje”",
  client_website_user_subscriptions_tab: "Zakładka „Subskrypcje”",
  client_website_payments_tab: "Zakładka „Płatności”",
  client_website_my_account_tab: "Zakładka „Moje konto”",
  invoice_warning_client_data:
    "Faktura VAT nie zostanie wystawiona z powodu braku pełnych danych do faktury klienta.",
  invoice_warning_fakturownia_disabled:
    "Faktura VAT nie zostanie wystawiona z powodu wyłączonej integracji z systemem Fakturownia.",
  default_price: "100,00 zł",
  added_location_notification: "Dodano lokalizację",
  updated_location_notification: "Zaktualizowano lokalizację",
  add_location_title: "Dodaj lokalizację",
  update_location_title: "Edytuj lokalizację",
  add_locations_with_plus:
    "Brak lokalizacji. Dodaj lokalizację, klikając „+” po prawej stronie.",
  add_availability_schedule_conditions_title: "Dodaj warunki",
  edit_availability_schedule_conditions_title: "Zmień warunki",
  add_availability_schedule_exception_title: "Dodaj wyjątek",
  edit_availability_schedule_exception_title: "Zmień wyjątek",
  availability_schedule_conditions_description:
    "Warunki umożliwiają przypisanie usług lub lokalizacji do określonych zakresów czasowych. Oznacza to, że gdy klient przegląda kalendarz dostępności, system automatycznie weryfikuje dostępność z uwzględnieniem dodatkowych kryteriów, takich jak wybrane usługi czy lokalizacje.",
  timezone: "Strefa czasowa",
  copy_hours_button_title: "Kopiuj godziny",
  copy_hours_from_monday_item: "Kopiuj godziny z poniedziałku",
  copy_hours_from_tuesday_item: "Kopiuj godziny z wtorku",
  copy_hours_from_wednesday_item: "Kopiuj godziny ze środy",
  copy_hours_from_thursday_item: "Kopiuj godziny z czwartku",
  copy_hours_from_friday_item: "Kopiuj godziny z piątku",
  copy_hours_from_saturday_item: "Kopiuj godziny z soboty",
  copy_hours_from_sunday_item: "Kopiuj godziny z niedzieli",
  add_hours_range_button_tooltip:
    "Dodaj nowy zakres godzin, który możesz przypisać do określonych lokalizacji lub usług.",
  add_hours_interval_button_tooltip:
    "Utwórz nowy przedział czasowy w obrębie istniejącego zakresu godzin.",
  delete_date_range_warning_title: "Usuń zakres dat",
  delete_date_range_warning_description:
    "Czy na pewno chcesz usunąć ten zakres dat? Spowoduje to wyłączenie wyjątków dla wybranych dni.",
  add_new_date_range_button_tooltip:
    "Dodaj wyjątki dla dni tygodnia, aby zmodyfikować standardowy grafik pracy.",
  exceptions_date_range_title: "Wyjątki - daty nadpisujące dni tygodnia",
  weekday_rules_title: "Standardowy tydzień pracy",
  exceptions_date_range_info:
    "Dodając daty wyjątków, możesz ustawić specjalny harmonogram na określone dni, ignorując tym samym standardowe ustawienia dla tych dni.",
  availability_schedule_assign_to_employees_info:
    "Pamiętaj, że każdy pracownik może mieć przypisany tylko jeden grafik dostępności. Przypisując nowy grafik dostępności do pracowników wymienionych poniżej, ich dotychczasowe grafiki zostaną zastąpione.",
  availability_schedule_assign_to_services_info:
    "Pamiętaj, że do każdej usługi można przypisać tylko jeden grafik dostępności. Usługi mogą wykorzystywać grafiki dostępności przypisane pracownikom lub posiadać własny, dedykowany grafik. Po przypisaniu wybranego grafiku dostępności do poniżej wymienionych usług, ich obecne ustawienia grafików zostaną nadpisane. Usługi te będą korzystać z wybranego grafiku dostępności.",
  other_settings_title: "Pozostałe ustawienia",
  subscription_status_info:
    "Aktywna subskrypcja daje klientom możliwość swobodnego zakupu i użytkowania, natomiast nieaktywna subskrypcja uniemożliwia dokonywanie nowych zakupów. Klienci, którzy wcześniej zakupili subskrypcję, mogą nadal z niej korzystać bez zmian, mimo jej dezaktywacji, ale nie będą mieli opcji zakupu nowej.",
  subscription_stop_automatic_payments_checkbox_title:
    "Przerwij pobieranie płatności i zakończ subskrypcję po określonym czasie",
  subscription_stop_automatic_payments_after: "Zakończ pobieranie płatności po",
  subscription_allow_users_to_cancel_checkbox_title:
    "Pozwól klientom na samodzielne anulowanie aktywnych subskrypcji w panelu klienta",
  subscription_prices_warning:
    "Pamiętaj, że kwota subskrypcji i ewentualny czas jej zakończenia nie mogą być zmieniane po jej utworzeniu. Jeśli chcesz zmodyfikować te parametry, musisz utworzyć nową subskrypcję.",
  cancel_subscription_dialog_title: "Anulowanie subskrypcji klienta",
  cancel_single_subscription_dialog_question:
    "Czy na pewno chcesz anulować wybraną subskrypcję?",
  cancel_multiple_subscription_dialog_question:
    "Czy na pewno chcesz anulować wybrane subskrypcje?",
  cancel_subscription_immediately_checkbox_title:
    "Zaznacz, aby anulować natychmiast, nie czekając do końca okresu.",
  cancel_single_subscription_confirm_button: "Anuluj subskrypcję",
  cancel_multiple_subscription_confirm_button: "Anuluj subskrypcje",
  selected_ids_label: "Wybrane numery Id",
  service_location_online: "Online",
  service_location_phone: "Telefon",
  service_location_name: "Nazwa",
  wb_select_locations_title_label: "Tekst przy wyborze lokalizacji",
  wb_select_services_title_label: "Tekst przy wyborze usługi",
  wb_select_employees_title_label: "Tekst przy wyborze pracownika",
  wb_select_locations_title_example_1: "Wybierz miejsce,",
  wb_select_locations_title_example_2: "aby wyświetlić dostępne usługi",
  wb_any_employee_enabled:
    "Automatycznie wybierz losowego pracownika, pomijając krok wyboru",
  wb_show_expand_collapse_button: "Pokazuj przycisk „Pokaż więcej godzin”",
  wb_show_booked_slots: "Pokazuj zarezerwowane terminy w kalendarzu",
  wb_initial_location_select:
    "Automatycznie wybieraj domyślnie pierwszą lokalizację",
  show_more_hours_button: "Pokaż więcej godzin",
  add_another_button_title: "Dodaj kolejne",
  wb_dummy_text_employee_description:
    "To jest przykładowy opis pracownika. Możesz go ustawić w panelu administratora Calendesk podczas edycji konta pracownika. Możesz wybrać dowolny tekst dla różnych pracowników.",
  any_person_label: "Dowolna osoba",
  selected_time_slots_label: "Wybrane terminy",
  logs_diff_phone_id: "ID telefonu",
  logs_diff_address_id: "ID adresu",
  user_time_zone_title: "Ustawienia języka i strefy czasowej",
  user_time_zone_locale_info:
    "Pracownicy mogą korzystać ze swojej strefy czasowej i wersji językowej. Rezerwacje oraz panel administratora będą wyświetlane zgodnie z ich wyborem. Jeśli nie zostanie nic wybrane, system automatycznie ustawi pracownikowi domyślną strefę czasową i język dla całego konta Calendesk, zgodnie z ustawieniami w zakładce „Ustawienia / Platforma”.",
  app_employees_time_zone_enabled_title:
    "Pozwól pracownikom na ustawianie własnej strefy czasowej",
  app_employees_locale_enabled_title:
    "Pozwól pracownikom na ustawianie własnego języka panelu",
  logs_diff_time_zone: "Strefa czasowa",
  logs_diff_locale: "Język",
  booking_reminders_headline: "Zarządzaj czasem wysyłki przypomnień",
  booking_reminders_info:
    "Nowe ustawienie czasu wysyłki powiadomień o przypomnieniach dotyczących rezerwacji będzie obowiązywać tylko dla rezerwacji utworzonych po wprowadzeniu zmiany.",
  booking_reminders_enable_custom_reminder_checkbox:
    "Chcę ustawić różne godziny wysyłki dla różnych typów przypomnień",
  booking_reminders_element_receiver_label: "Odbiorca",
  booking_reminders_headline_2: "Aktualne przypomnienia",
  booking_reminders_recipient_type_1: "Klient",
  booking_reminders_recipient_type_2: "Pracownik",
  booking_reminders_add_new_button: "Dodaj nowe przypomnienie",
  booking_reminders_no_reminders_info_label:
    "Brak ustawionych przypomnień do rezerwacji, kliknij poniżej, aby dodać pierwsze przypomnienie. 👇",
  booking_reminders_added_successfully_label:
    "Powiadomienia zostały dodane poprawnie",
  captcha_invalid_error_notification:
    "Weryfikacja captcha niepoprawna. Spróbuj przeładować stronę, jeśli problem będzie się powtarzać.",
  verify_code_incorrect_error: "Wprowadzony kod jest niepoprawny.",
  verify_code_too_many_requests:
    "Obecnie nie możemy wygenerować nowego kodu. Spróbuj ponownie za jakiś czas lub wykorzystaj kod, który wysłaliśmy.",
  send_again_button_title: "Wyślij ponownie",
  verify_title: "Weryfikuj",
  verify_code_title: "Wprowadź kod weryfikacyjny",
  verify_code_description:
    "Wysłaliśmy 6-cyfrowy kod weryfikacyjny na Twój e-mail. Proszę wprowadź go poniżej:",
  general_settings_title: "Ustawienia ogólne",
  bookings_settings_title: "Ustawienia rezerwacji",
  employees_settings_title: "Ustawienia pracowników",
  create_booking_email_verification_required_checkbox:
    "Wymagaj potwierdzenia e-mail od niezalogowanych klientów podczas tworzenia rezerwacji",
  verification_invalid_error_notification:
    "Nieprawidłowa weryfikacja. Spróbuj ponownie później.",
  too_many_requests:
    "Ups! Działasz trochę za szybko. Zwolnij i spróbuj ponownie za chwilę.",
  notification_category_0: "Powiadomienie systemowe",
  notification_category_1: "Przedpłata rezerwacji",
  notification_category_2: "Potwierdzenie rezerwacji",
  notification_category_3: "Zmiana rezerwacji",
  notification_category_4: "Usunięcie rezerwacji",
  notification_category_5: "Przypomnienie o rezerwacji",
  notification_category_6: "Aktywacja konta",
  notification_category_7: "Reset hasła",
  notification_category_8: "Transakcja w sklepie",
  notification_category_9: "Linki do aplikacji mobilnej",
  notification_category_10: "Powiadomienie własne",
  notification_category_11: "Subskrypcja newslettera",
  notification_category_12: "Kod weryfikacyjny",
  notification_category_13: "Kontakt ze strony internetowej",
  notification_status_0: "Nieznany",
  notification_status_1: "Wysłano",
  notification_status_2: "Dostarczono",
  notification_status_3: "Nie dostarczono",
  notification_status_4: "Wystąpił błąd",
  notification_status_5: "Kliknięto link w e-mailu",
  notification_status_6: "Otwarto e-mail",
  errors: "Błędy",
  enter_image_url: "Wprowadz adres URL obrazka",
  enter_url: "Wprowadz adres URL",
  html_editor_tooltip_bold: "Pogrubienie tekstu",
  html_editor_tooltip_italic: "Kursywa",
  html_editor_tooltip_underline: "Podkreślenie",
  html_editor_tooltip_strike: "Przekreślenie",
  html_editor_tooltip_paragraph: "Akapit",
  html_editor_tooltip_heading: "Nagłówek",
  html_editor_tooltip_bullet_list: "Lista punktowana",
  html_editor_tooltip_code_block: "Blok kodu",
  html_editor_tooltip_horizontal_rule: "Linia pozioma",
  html_editor_tooltip_hard_break: "Odstęp",
  html_editor_tooltip_add_mage: "Dodaj obraz",
  html_editor_tooltip_link: "Dodaj link",
  html_editor_tooltip_unlink: "Usuń link",
  html_editor_tooltip_font_size: "Rozmiar czcionki",
  html_editor_tooltip_font_color: "Kolor czcionki",
  html_editor_tooltip_background_color: "Kolor tła",
  html_editor_tooltip_delete_background_color: "Usuń kolor tła",
  html_editor_tooltip_set_html: "Wstaw HTML",
  html_editor_tooltip_undo: "Cofnij",
  html_editor_tooltip_redo: "Ponów",
  html_editor_tooltip_align_left: "Wyrównaj do lewej",
  html_editor_tooltip_align_right: "Wyrównaj do prawej",
  html_editor_tooltip_align_center: "Wyrównaj do środka",
  html_editor_tooltip_align_justify: "Wyrównaj obustronnie",
  subscription_cancel_reason_info:
    "Pomóż nam ulepszyć nasze usługi, aby lepiej odpowiadały Twoim potrzebom! Podziel się z nami powodem rezygnacji z subskrypcji, a dzięki Twojej opinii przyszłe rozwiązania będą bardziej dostosowane do Ciebie.",
  subscription_cancel_reason_placeholder: "Twoja opinia",
  subscription_cancel_error:
    "Coś poszło nie tak. Sprawdź, czy masz aktywną subskrypcję i czy nie zalegasz z płatnościami. Skontaktuj się z nami, aby uzyskać więcej informacji.",
  subscription_cancel_data_warning:
    "Po zakończeniu subskrypcji wszystkie dane z systemu rezerwacji mogą zostać bezpowrotnie usunięte.",
  reply_to_label: "Odpowiedz do",
  change_sender_name_button: "Zmień nazwę nadawcy",
  change_sender_name_info:
    "Jeśli chcesz zmienić nazwę nadawcy wyświetlaną przy wysyłce Twoich powiadomień, kliknij poniższy przycisk i przejdź do ustawień firmowych.",
  wb_expand_collapse_service_panels: "Grupuj usługi w kategorie",
};
